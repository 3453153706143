<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details :title="sidebar_title" @close-sidebar="closeSidebar">
                <service-details v-if="sidebar.service_details"
                    :uuid="service_uuid"

                    @close-sidebar="closeSidebar"
                />

                <service-notes v-else-if="sidebar.service_notes"
                    :uuid="service_uuid"

                    ref="service_notes"

                    @close-sidebar="closeSidebar"
                />
            </app-sidebar-details>
        </template>

        <div class="page-fibre-services container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Fibre Services</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <!-- @todo -->
                    <!-- <app-error v-model="errors.show" :message="errors.message" /> -->

                    <div class="board">
                        <div class="form-container">
                            <div class="form-group">
                                <div class="form-controls">
                                    <span class="title">Date:</span>

                                    <app-date-range
                                        v-model="date_range"

                                        divider="to"

                                        :locale-data="{ format: 'YYYY-MM-DD' }"

                                        @change="onDateRangeChange"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <app-table
                        class="fibre-services-table"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :selected-row="service_uuid"

                        :cols="cols"
                        :rows="services"

                        :loading="loading"

                        @toggle-service-details="onToggleServiceDetailsSidebar"
                        @toggle-service-notes="onToggleServiceNotesSidebar"

                        @remove-device="onRemoveDevice"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>

                <div class="col-12">
                    <app-adding-banner-service service="Fibre" :tablet-mode="true" />
                </div>
            </div>
        </div>

        <app-dialog-device-delete
            v-if="dialogs.devices.remove.show"
            v-model="dialogs.devices.remove.show"

            :uuid="dialogs.devices.remove.uuid"
            :spid="dialogs.devices.remove.spid"

            @close="onDeviceDeleteClose"
            @confirm="onDeviceDeleteConfirm"
        />
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import serviceDetails from '@/views/fibre/service-details'
import serviceNotes from '@/views/fibre/service-notes'

import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appDateRange from '@/components/app-date-range'
import appAddingBannerService from '@/components/app-adding-banner-service'

import appDialogDeviceDelete from './components/app-dialog-device-delete'

import formatDateNano from '@/helpers/format-date-nano'
import errMessage from '@/helpers/errMessage'

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        serviceDetails,
        serviceNotes,

        appError,
        appTable,
        appPagination,
        appDateRange,
        appAddingBannerService,

        appDialogDeviceDelete,
    },

    data() {
        return {
            date_range: {
                startDate: null,
                endDate: null,
            },

            loading: false,

            filter: {
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc'
                },
            },

            sort: [
                { field: 'UUID',           title: 'Service UUID' },
                { field: 'Address',        title: 'Address'      },
                { field: 'State',          title: 'State'        },
                { field: 'CreatedAtNanos', title: 'Date'         },
            ],

            cols: [
                { key: 'uuid',    title: 'Service UUID', sort: { field: 'UUID'           }, highlight: true },
                { key: 'address', title: 'Address',      sort: { field: 'Address'        }                  },
                { key: 'state',   title: 'State',        sort: { field: 'State'          }                  },
                { key: 'date',    title: 'Date',         sort: { field: 'CreatedAtNanos' }                  },

                { actions: [
                    { action: 'toggle-service-details', title: 'Service details' },
                    { action: 'toggle-service-notes',   title: 'Service notes'   },
                ], behavior: 'detached', },

                { key: 'remove', action: 'remove-device', icon: 'remove', behavior: 'detached' },
            ],

            services: [],

            service_uuid: null,

            is_mobile_mode: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                devices: {
                    remove: {
                        show: false,
                        uuid: null,
                        spid: null,
                    },
                },
            },

            sidebar: {
                service_details: false,
                service_notes:   false,
            },

            // errors: {
            //     show: false,
            //     message: '',
            // },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getFibreServices()
        },

        handleWindowResize() {
            this.is_mobile_mode = document.body.clientWidth <= this.$mobile_size
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getFibreServices()
        },

        onPageChange(page) {
            this.getFibreServices(page)
        },

        onDateRangeChange() {
            console.warn('onDateRangeChange() {')

            this.pagination.page = 1

            this.getFibreServices()
        },

        getFibreServices(page) {
            this.loading = true

            const filter = {
                ...this.filter,
            }

            const params = {
                'SPID': this.current_spid,

                'State': 'Active',

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     filter.sort.field,
                'SearchOptions.SortDesc':   filter.sort.order === 'desc' ? 1 : 0,
            }

            if (this.date_range.startDate && this.date_range.endDate) {
                console.log('this.date_range.startDate', this.date_range.startDate)

                console.log('this.date_range.endDate', this.date_range.endDate)

                /**
                 * @todo
                 */
            }

            this.$store.dispatch('api_ufbprod/GetUFBProductsPaginated', params )
                .then(({ PageInfo, UFBProducts }) => {
                    this.services = UFBProducts.map(service => ({
                        uuid:    service.UUID,
                        address: service.Address,
                        state:   service.State,
                        date:    service.CreatedAtNanos ? formatDateNano(service.CreatedAtNanos) : '?',

                        remove: {
                            disabled: service.State == 'PendingTerminate',
                        },
                    }))

                    const { DisplayPageList, PageNumber, TotalItemCount } = PageInfo

                    this.pagination.page = PageNumber
                    this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                    }

                    this.loading = false
                })
                .catch(error => {
                    console.log(error)

                    this.services = []

                    this.loading = false
                })
        },

        onRemoveDevice(device) {
            this.dialogs.devices.remove.show = true
            this.dialogs.devices.remove.uuid = device.uuid
            this.dialogs.devices.remove.spid = this.current_spid
        },

        onDeviceDeleteConfirm() {
            if (this.service_uuid == this.dialogs.devices.remove.uuid) {
                this.closeSidebar()
            }

            this.getFibreServices()

            this.dialogs.devices.remove.show = false
            this.dialogs.devices.remove.uuid = null
        },

        onDeviceDeleteClose() {
            this.dialogs.devices.remove.show = false
            this.dialogs.devices.remove.uuid = null
        },

        onToggleServiceDetailsSidebar(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'fibre-service-details', params: { uuid: row.uuid } })
            } else {
                for (const key in this.sidebar) {
                    if (key != 'service_details') {
                        this.sidebar[key] = false
                    }
                }

                if (this.sidebar.service_details && (this.service_uuid == row.uuid)) {
                    this.service_uuid = null

                    this.closeSidebar()
                } else {
                    this.sidebar.service_details = true

                    this.service_uuid = row.uuid

                    this.openSidebar()
                }
            }
        },

        onToggleServiceNotesSidebar(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'fibre-service-notes', params: { uuid: row.uuid } })
            } else {
                for (const key in this.sidebar) {
                    if (key != 'service_notes') {
                        this.sidebar[key] = false
                    }
                }

                if (this.sidebar.service_notes && (this.service_uuid == row.uuid)) {
                    this.service_uuid = null

                    this.closeSidebar()
                } else {
                    this.sidebar.service_notes = true

                    this.service_uuid = row.uuid

                    this.openSidebar()
                }
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.service_uuid = null

            this.$emit('close-sidebar')

            for (const key in this.sidebar) {
                if (this.$refs[key] && typeof this.$refs[key].reset == 'function') {
                    this.$refs[key].reset()
                }
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            '$mobile_size',
        ]),

        sidebar_title() {
            let title = ''

            switch (true) {
                case this.sidebar.service_details : {
                    title = 'Service details'
                } break

                case this.sidebar.service_notes : {
                    title = 'Service notes'
                } break
            }

            return title
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
.page-fibre-services {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .board {
        margin-bottom: 30px;
        padding: 24px #{ 24px - 15px };

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        .form-container {
            display: flex;

            .form-group {
                display: flex;
                width: 100%;

                .form-controls {
                    display: flex;
                    align-items: center;
                    min-width: 25%;
                    max-width: 100%;

                    padding: 0 15px;

                    & > .title {
                        flex-shrink: 0;
                        margin-right: 16px;
                        color: var(--color-table-secondary);
                    }
                }
            }
        }
    }

    .fibre-services-table {
        @include table-cols-width((330px, 330px, 150px, 150px, 48px, 48px), true, 2);
    }

    .app-pagination,
    .app-adding-banner-service {
        margin-top: 30px;
    }
}

@media (max-width: $mobile-size) {
    .page-fibre-services {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

    .board {
        .form-container {
            .form-group {
                .form-controls {
                    padding: 0;

                    & > .title {
                        display: none;
                    }
                }
            }
        }
    }

        .fibre-services-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }

        .app-adding-banner-service {
            margin-top: 15px;
        }
    }
}
</style>