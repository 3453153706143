<template>
    <app-dialog-info
        class="app-dialog-device-delete"
        v-model="show"
        v-if="show"
        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Service removal</h2>
        </template>

        <template #body>
            <app-loader v-if="loading"></app-loader>

            <div class="wrap">
                <app-error v-model="error" :message="error"></app-error>

                <div class="content">
                    <p v-if="removed">Terminate order has been created</p>

                    <template v-else>
                        <p>You have selected to delete this service.</p>
                        <p>If this was the action that you want to do, please confirm your choice, or cancel and return to the page.</p>
                    </template>
                </div>
                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="confirm">Confirm</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close" v-if="!removed">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:         { required: true                       },
        maxWidth:      { type: [Number, String], default: 610 },
        uuid:          { required: true                       },
        spid:          { required: true                       },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
    },

    data() {
        return {
            removed: false,

            loading: false,

            error: null,
        }
    },

    computed: {
        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },
    },

    methods: {
        confirm() {
            if (this.removed) {
                this.$emit('confirm')
                this.close()
            } else {
                this.error = null
                this.loading = true

                const payload = {
                    UUID: this.uuid,
                    SPID: this.spid,
                    LocationOfEquipment: '',
                    TerminateAtNanos: 0,
                }

                this.$store.dispatch('api_ufbprod/DeleteUFBProductByUUID', payload).then(({ service }) => {
                    this.removed = true
                }).catch(error => {
                    this.error = errMessage(error)
                }).finally(() => {
                    this.loading = false
                })
            }
        },

        close() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-device-delete {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }

        .btns {
            .btn {
                transition: $transition-duration-primary, margin 0s;
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-device-delete {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>