<template>
    <div id="page-fibre-service-notes" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to Fibre Services</router-link>

                    <h1 class="heading">Service notes</h1>
                </div>

                <div class="col-6 col-tab-12">
                    <div class="board">
                        <h3 class="heading" v-if="on_sidebar">Service notes</h3>

                        <div class="form-group">
                            <div class="form-controls">
                                <app-textarea
                                    v-model="note"

                                    label="Write a note..."

                                    :required="true"
                                    :error="errors.note"

                                    @change="errors.note = null"
                                />

                                <app-error v-model="errors.save" />

                                <button class="btn btn-primary btn-post" :disabled="loading" @click="post">Submit</button>
                            </div>
                        </div>

                        <div class="notes" v-if="has_at_least_one_note">
                            <div class="note" v-for="note in notes_list" :key="note.UUID">
                                <div class="details">
                                    <div class="author">{{ note.author }}</div>
                                    <div class="date">{{ note.date }}</div>
                                </div>
                                <div class="message">{{ note.message }}</div>
                            </div>
                        </div>

                        <app-pagination
                            v-if="pagination.total"

                            v-model="pagination.page"

                            :total="pagination.total"
                            :limit="pagination.limit"
                            :page-range="pagination.range"

                            @change="onPageChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTextarea from '@/components/app-textarea'
import appPagination from '@/components/app-pagination'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        appTextarea,
        appPagination,
    },

    data() {
        return {
            note: null,

            filter: {
                sort: {
                    field: 'TimestampNanos',
                    order: 'desc',
                },
            },

            notes: [],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,

            referrer: null,
            on_sidebar: false,

            errors: {},
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            if (this.service_uuid) {
                this.getNotes()
            }
        },

        getNotes(page) {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                UUID: this.service_uuid,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_ufbprod/GetUFBProductNotes', params)
                .then(({ Notes, PageInfo }) => {
                    this.notes = Array.isArray(Notes) ? Notes : []

                    this.pagination.page = PageInfo.PageNumber
                    this.pagination.total = PageInfo.TotalItemCount

                    this.errors = {}

                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        validation() {
            this.errors = {}

            let is_valid = true

            const error = typeof this.note == 'string' && this.note.trim().length
                ? null
                : 'Please, write a note'

            if (error) {
                is_valid = false
                this.errors.note = error
            }

            return is_valid
        },

        post() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    SPID: this.current_spid,
                    UUID: this.service_uuid,
                    NoteText: this.note.trim(),
                    AddedBy: this.added_by,
                }

                this.$store.dispatch('api_ufbprod/AddUFBProductNote', params)
                    .then(() => {
                        this.note = null

                        this.getNotes()
                    })
                    .catch(error => {
                        this.errors.save = errMessage(error)
                        this.loading = false
                    })
            }
        },

        reset() {
            this.note = null

            this.pagination.page = 1
            this.pagination.total = 0

            this.errors = {}
        },

        onPageChange(page) {
            this.getNotes(page)
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',

            'id_first_name',
            'id_last_name',
            'id_mobile',
        ]),

        added_by() {
            // return `${ this.id_first_name } ${ this.id_last_name } (${ this.id_mobile })`

            return `${ this.id_first_name } ${ this.id_last_name }`
        },

        back_to() {
            return this.referrer && this.referrer.name == 'fibre-services'
                ? this.referrer
                : { name: 'fibre-services' }
        },

        service_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        notes_list() {
            return this.notes.map(note => ({
                UUID: note.UUID,
                author: note.AddedBy
                    ? note.AddedBy.indexOf(' (') != -1
                        ? note.AddedBy.slice(0, note.AddedBy.indexOf(' ('))
                        : note.AddedBy
                    : '?',
                date:  note.TimestampNanos
                    ? formatDateNano(note.TimestampNanos, 'YYYY-MM-DD HH:mm:ss')
                    : '?',
                message: note.NoteText
                    ? note.NoteText
                    : '?',
            }))
        },

        has_at_least_one_note() {
            return this.notes.length > 0
        },
    },

    watch: {
        uuid() {
            this.reset()

            if (this.service_uuid) {
                this.getNotes()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-fibre-service-notes {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin: 0 0 24px 0;
    }

    .board {
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        h3 {
            &.heading {
                margin-bottom: 24px;
            }
        }

        .form-group {
            margin-bottom: 32px;

            .form-controls {
                display: flex;
                flex-direction: column;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .app-textarea {
            height: 144px;

            margin-bottom: 24px;
        }

        .notes {
            .note {
                padding: 16px 24px;

                font-size: 16px;
                line-height: 24px;

                color: var(--color-comments-primary);

                border-bottom: 1px solid var(--color-divider);

                .details {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;

                    .author {
                        font-weight: bold;
                    }

                    .date {
                        font-size: 14px;

                        color: var(--color-comments-secondary);
                    }
                }

                .message {
                    word-break: break-word;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .app-pagination {
            margin-top: 24px;
        }
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .board {
            .form-group {
                .form-controls {
                    align-items: center;
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-fibre-service-notes {
        .board {
            padding: 8px;

            .form-group {
                margin-bottom: 24px;

                .form-controls {
                    align-items: center;
                }
            }

            .app-textarea {
                height: 129px;
            }

            .notes {
                .note {
                    padding: 8px 0;

                    font-size: 14px;
                }
            }

            .app-pagination {
                margin-top: 16px;
            }
        }
    }
}
</style>